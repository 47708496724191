exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-elements-of-an-engineering-organization-js": () => import("./../../../src/pages/blog/elements-of-an-engineering-organization.js" /* webpackChunkName: "component---src-pages-blog-elements-of-an-engineering-organization-js" */),
  "component---src-pages-blog-pragmatic-pull-requests-js": () => import("./../../../src/pages/blog/pragmatic-pull-requests.js" /* webpackChunkName: "component---src-pages-blog-pragmatic-pull-requests-js" */),
  "component---src-pages-blog-release-planner-js": () => import("./../../../src/pages/blog/release-planner.js" /* webpackChunkName: "component---src-pages-blog-release-planner-js" */),
  "component---src-pages-blog-release-trains-js": () => import("./../../../src/pages/blog/release-trains.js" /* webpackChunkName: "component---src-pages-blog-release-trains-js" */),
  "component---src-pages-blog-roadmap-planner-js": () => import("./../../../src/pages/blog/roadmap-planner.js" /* webpackChunkName: "component---src-pages-blog-roadmap-planner-js" */),
  "component---src-pages-blog-testing-js": () => import("./../../../src/pages/blog/testing.js" /* webpackChunkName: "component---src-pages-blog-testing-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-home-js": () => import("./../../../src/pages/new-home.js" /* webpackChunkName: "component---src-pages-new-home-js" */),
  "component---src-pages-orange-js": () => import("./../../../src/pages/orange.js" /* webpackChunkName: "component---src-pages-orange-js" */),
  "component---src-pages-policies-community-terms-of-use-for-members-js": () => import("./../../../src/pages/policies/community-terms-of-use-for-members.js" /* webpackChunkName: "component---src-pages-policies-community-terms-of-use-for-members-js" */),
  "component---src-pages-policies-index-js": () => import("./../../../src/pages/policies/index.js" /* webpackChunkName: "component---src-pages-policies-index-js" */),
  "component---src-pages-policies-unaty-community-code-of-conduct-for-users-js": () => import("./../../../src/pages/policies/unaty-community-code-of-conduct-for-users.js" /* webpackChunkName: "component---src-pages-policies-unaty-community-code-of-conduct-for-users-js" */),
  "component---src-pages-policies-unaty-community-code-of-conduct-js": () => import("./../../../src/pages/policies/unaty-community-code-of-conduct.js" /* webpackChunkName: "component---src-pages-policies-unaty-community-code-of-conduct-js" */),
  "component---src-pages-policies-unaty-community-data-processing-agreement-for-customers-js": () => import("./../../../src/pages/policies/unaty-community-data-processing-agreement-for-customers.js" /* webpackChunkName: "component---src-pages-policies-unaty-community-data-processing-agreement-for-customers-js" */),
  "component---src-pages-policies-unaty-community-terms-and-conditions-for-customers-js": () => import("./../../../src/pages/policies/unaty-community-terms-and-conditions-for-customers.js" /* webpackChunkName: "component---src-pages-policies-unaty-community-terms-and-conditions-for-customers-js" */),
  "component---src-pages-policies-unaty-data-privacy-policy-for-users-js": () => import("./../../../src/pages/policies/unaty-data-privacy-policy-for-users.js" /* webpackChunkName: "component---src-pages-policies-unaty-data-privacy-policy-for-users-js" */),
  "component---src-pages-policies-unaty-overview-and-definitions-js": () => import("./../../../src/pages/policies/unaty-overview-and-definitions.js" /* webpackChunkName: "component---src-pages-policies-unaty-overview-and-definitions-js" */),
  "component---src-pages-policies-unaty-usage-terms-for-users-js": () => import("./../../../src/pages/policies/unaty-usage-terms-for-users.js" /* webpackChunkName: "component---src-pages-policies-unaty-usage-terms-for-users-js" */),
  "component---src-pages-policies-unaty-website-privacy-and-cookie-policy-js": () => import("./../../../src/pages/policies/unaty-website-privacy-and-cookie-policy.js" /* webpackChunkName: "component---src-pages-policies-unaty-website-privacy-and-cookie-policy-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-whats-new-js": () => import("./../../../src/pages/whats-new.js" /* webpackChunkName: "component---src-pages-whats-new-js" */)
}

